import { useStableProps } from '@/hooks/useStable';
import StandaloneVideoSlider from 'base/components/VideoSlider';

export const FeminaStandaloneVideoSlider: typeof StandaloneVideoSlider = (props) => {
  const stableProps = useStableProps(
    {
      options: {
        $swiper: { $arrow: { className: 'text-gray-300' } },
        $header: { $arrow: { className: 'md:w-2 mt-0.75 md:mt-1.25 ml-1' } },
      },
    },
    props,
  );

  return <StandaloneVideoSlider {...stableProps} />;
};

export default FeminaStandaloneVideoSlider;
